<template>
  <div>
    <Header active_tab="profile"></Header>
    <hr />
    <div
      class="
        details
        d-flex
        flex-column
        justify-content-center
        align-items-center
      "
    >
      <h3>Hello {{ user.data.username }}</h3>
      <div class="container my-3">
        <div class="row my-4">
          <div class="col">
            <label>Update Username</label>
            <input
              v-model="name"
              type="text"
              class="form-control"
              placeholder="Update Name"
            />
          </div>
          <div class="col">
            <button class="btn" @click="changeUsername">Update</button>
          </div>
        </div>
        <div class="row my-4">
          <div class="col">
            <label>Update Email</label>
            <input
              v-model="email"
              type="email"
              class="form-control"
              placeholder="Update Email"
            />
          </div>
          <div class="col">
            <button class="btn" @click="changeEmail">Update</button>
          </div>
        </div>
        <!-- <div class="row my-4">
            <div class="col">
                <label>Update Password</label>
                <input v-model="password" type="password" class="form-control " placeholder="Update Password"/>
            </div>
            <div class="col">
                <button class="btn" @click="changePassword">Update</button>
            </div>
        </div> -->
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
import firebase from "firebase";
import store from "@/store";
import { defineAsyncComponent } from "vue";

const Header = defineAsyncComponent(() => import("@/components/Header"));

export default {
  name: "EditProfile",
  components: { Header },
  mounted() {
    store.state.loading = false;
  },
  data() {
    return {
      name: "",
      email: "",
      password: "",
    };
  },
  computed: {
    ...mapGetters({
      user: "user",
    }),
  },

  methods: {
    changeUsername() {
      var user = firebase.auth().currentUser;
      user
        .updateProfile({
          displayName: this.name,
        })
        .then(function () {
          swal("Username Changed");
        })
        .catch(function (error) {
          swal(error["message"]);
        });
    },
    changeEmail() {
      var user = firebase.auth().currentUser;
      user
        .updateEmail(this.email)
        .then(function () {
          swal("Email Changed");
        })
        .catch(function (error) {
          swal(error["message"]);
        });
    },
    // changePassword(){
    //     var user = firebase.auth().currentUser;
    //     user.updatePassword(this.password).then(function() {
    //     swal("Password Changed");
    //     }).catch(function(error) {
    //     swal(error['message']);
    //     });
    // }
  },
};
</script>
<style scoped>
label {
  font-weight: 500;
}
hr {
  margin: 0;
  margin-bottom: 20px;
}
h3 {
  font-size: 28px;
  margin: 5px;
  margin-bottom: 40px;
  padding: 0;
  line-height: 1;
  font-weight: 700;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}
.btn {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  width: 150px;
  background-color: #d42525;
  border: none;
  outline: none;
  height: 49px;
  border-radius: 49px;
  color: #fff;
  text-transform: uppercase;
  font-weight: 600;
  margin: 10px 0;
  cursor: pointer;
  transition: 0.5s;
  margin: auto;
}
</style>
